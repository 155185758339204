.nftPicDiv {
  margin-bottom: 5%;
}

@media screen and (max-width: 499px) {


  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
    font-size: 22px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    background: -webkit-linear-gradient(#f7d724, #7deedb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Doctor Glitch', sans-serif;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .countdown {
    text-align: center;
    color: black;
    padding: 30px;
    font-size: 20px;
  }

  .days {
    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    padding: 10px;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    padding: 10px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
    font-size: 25px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    background: -webkit-linear-gradient(#f7d724, #7deedb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Doctor Glitch', sans-serif;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

  }

  .countdown {
    text-align: center;
    color: black;
    padding: 30px;
    font-size: 25px;
    margin-bottom: 20%;

  }

  .days {

    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    width: 100px;
    height: 100px;
    padding-top: 25px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    color: black;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    width: 100px;
    height: 100px;
    padding-top: 25px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
    font-size: 50px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    background: -webkit-linear-gradient(#f7d724, #7deedb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Doctor Glitch', sans-serif;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;

  }

  .countdown {
    text-align: center;
    color: black;
    padding: 30px;
    font-size: 35px;
  }

  .days {

    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    width: 150px;
    height: 150px;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    color: black;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    width: 150px;
    height: 150px;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {


  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    background: -webkit-linear-gradient(#f7d724, #7deedb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Doctor Glitch', sans-serif;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .countdown {
    text-align: center;
    color: black;
    font-size: 60px;
  }


  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .days {
    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    color: black;

     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
     width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;

     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    color: white;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    background: -webkit-linear-gradient(#f7d724, #7deedb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Doctor Glitch', sans-serif;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .countdown {
    text-align: center;
    color: black;
    font-size: 60px;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .days {
    background-color: #a0ff06;
    font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 4px;
    border-left-color: white;
    border-top-color: white;
    width: 170px;
    height: 170px;
    color: white;
     padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    color: black;
    margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff06;
     font-family: 'Doctor Glitch', sans-serif;
     border: rgb(108, 153, 2) solid 4px;
     border-left-color: white;
     border-top-color: white;
    border-top-color: #fff;
    border-left-color: #fff;
      width: 170px;
    height: 170px;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;

    margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}

@media screen and (min-width: 1920px) {

  .cs {
    font-family: 'Ubuntu', sans-serif;
    margin-top: 20px;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    background: -webkit-linear-gradient(#f7d724, #7deedb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Doctor Glitch', sans-serif;
  }

  .dots {
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

  }

  .countdown {
    text-align: center;
    color: black;
    font-size: 80px;

  }

  .days {

    background-color: #a0ff06;
     font-family: 'Doctor Glitch', sans-serif;
     border: rgb(108, 153, 2) solid 8px;
    width: 250px;
    height: 250px;
    padding-top: 67px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    color: black;
    border-left-color: white;
    border-top-color: white;
     margin-right: 5px;
    margin-left: 5px;

  }

  .sec {
    animation: 1s 0.875s infinite timer_beat;
    background-color: #a0ff06;
     font-family: 'Doctor Glitch', sans-serif;
    border: rgb(108, 153, 2) solid 8px;
    width: 250px;
    height: 250px;
    padding-top: 67px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 25px;
    border-left-color: white;
    border-top-color: white;
     margin-left: 5px;

  }

  @keyframes timer_beat {
    0% {
      transform: scale(1.125);
    }

    50% {
      transform: none;
    }
  }
}